
import haircarebanner from './HAIRE CARE BANNNER.png'
import skincarebaner from './banner for skins care.png'
import Dietbanner from './dietary use banner of exotic veda-01.png'
import collage from './Collage 2-01.png'

import ty from './Exotic_Veda_by_Revaais (1).jpg'
// =====home banner======
import homebanner2 from './Skincare Beauty Product Features Banner Facebook Cover (2).png'
import homebanner1 from './Skincare Beauty Product Features Banner Facebook Cover (6).webp'
import homebanner6 from './Happy new year .png'
import homebanner7 from './New Products .png'
// import  homebanner1 from  './Skincare Beauty Product Features Banner Facebook Cover (6).png'
import homebanner3 from './Skincare Beauty Product Features Banner Facebook Cover (4).png'
import homebanner4 from './banner for amzon store.png'
import homebanner5 from './Exotic veda Alum Bar (1).png'

// ==Shika kai images ====
import shika2 from '../shikakipowder/Shikakai  Powder  Frontn.png'
import shika1 from '../shikakipowder/skikhai.png'
import shika3 from '../shikakipowder/shikakai.png'
import shika4 from '../shikakipowder/shikaaipowder.jpg'
import shika5 from '../shikakipowder/shikakai powdder.jpg'
import shikabannersttre from '../shikakipowder/WhatsApp Image 2024-09-09 at 13.42.59_799fa071.png'
import shikabanner from '../shikakipowder/shika970.png'
// =====end of shikaa ====

// ======sandalwood powder ======
import sandalfront from '../sabdalwood/sandalwood Fornt.png'
import sandalback from '../sabdalwood/sandalwood  powder back.png'
import sandaluse from '../sabdalwood/sandal how to  use.png'
import sandaluse2 from '../sabdalwood/sandalwood powder.png'
import sandaluse3 from '../sabdalwood/san.jpg'
import sandaluse4 from '../sabdalwood/sande.jpg'
import sandal1 from '../sabdalwood/Sandalwood 2 banner (1).png'
import sandalcertification from '../sabdalwood/sandalwwod-certifiation-banner.png'

// ===Alium BAr  ===
import alum1 from '../Alumbar/aftershave.jpg'
import alumfront from '../Alumbar/Alum bar Front.png'
import alumuse from '../Alumbar/Alum bar (2).png'
import alum2 from '../Alumbar/banner2.jpg'
import alum3 from '../Alumbar/deobanner.jpg'
import alum4 from '../Alumbar/aftershave.jpg'
import alum55 from '../Alumbar/alumbar.jpg'
import alum from '../Alumbar/all.jpg'
import alum5 from '../Alumbar/alum block benefits.png'
// ====Amla powdder  imports =====
import Amla1 from '../AmlaAseets/Amla Powderfront.png'
import Amla2 from '../AmlaAseets/Amla Powderfront.png'
import Amla3 from '../AmlaAseets/Amla _06.jpg'
import Amla4 from '../AmlaAseets/Amla _05.jpg'
import Amla5 from '../AmlaAseets/Amla powder (1).png'

import amlabann from '../AmlaAseets/Amla banner.png'
// import amlabannn from './WhatsApp Image 2024-09-25 at 13.35.57_eed9d8b7.jpg'
import amlaposter from '../AmlaAseets/AMLA POWEDER POSTERS BANNER_Vegan Circle Green.png'
import amlabenefits from '../AmlaAseets/Amalabenefit.png'
import amlaben from '../AmlaAseets/benefit  of amla.png'
import amlafetures from '../AmlaAseets/amlfet.png'
import amalpowderbowl from '../AmlaAseets/amla owder.png'
// ======Amla End  =============
import jamuseed from '../jamunseed/jamunf.jpg'
// ================Aloe vera pictures  ====================
import aloebanner1 from '../Aloe/aloe ver banner stretch_Vegan Circle Green copy (1).png'
import aloebanner2 from './Aloe vera Powwder banner.png'
import aloe3 from '../Aloe/Aloe vera  front.png'
import aloe4 from '../Aloe/aloeverapowder.png'
import aloeuse1 from '../Aloe/aloeuse1.jpg'
import aloeuse2 from '../Aloe/close-up-woman-wearing-face-mask.jpg'
import aloeback from '../Aloe/Amazon.png'

import aloebene from '../Aloe/aloebenifts.png'
import aloehair from '../Aloe/haircareimg.webp'
import multaniposter from '../multani/Multani-mitti-banner.png'
import multani from '../multani/32.png'
import multani33 from '../multani/multani.jpg'
import multanimitti45 from '../multani/multani Mitti  Exotic VEda .png'
import multanimitti4 from '../multani/multni mitti uaing.png'
import multanimitti343 from './ghjgj.jpg'

import multanibanner1 from '../multani/Multani mitti-01.png'


// ====multani end  =============


// =======Curry leaf  powder =======
import curryleafposter from '../curryleaf/curry-leaf-banner-2.png'
import curryleafposter2 from '../curryleaf/Curry Leaf Powder.png'
import Curryfront from '../curryleaf/Curry Front.png'
import Curryfetures from '../curryleaf/Cruuty f3.png'
import Currybenefits from '../curryleaf/Curry Benfits.png'
import curryuse from '../curryleaf/usecurry.jpg'
import curryback from '../curryleaf/curryback.png'
// import Curryfront from './Curry Front.png'
// =======Curry leaf  powder end =======
// ======henna  Powder ==============
import Hennabanner1 from '../hennapowder/henna Powdder Banner  Long.png'
import Hennabanner2 from '../hennapowder/henna Powder banner.png'
import henna from '../hennapowder/33.png'
import hennabanner from '../hennapowder/henna.jpeg'
import hennaback from '../hennapowder/hennaback.png'
import hennause from '../hennapowder/Henna HowTo use .png'
import hennadiy from '../hennapowder/hair.jpg'
import hennabowl from '../hennapowder/henass.jpg'
// ====henan  powder edn ======

// ====Lemongradss===
import lemonstrexh from '../Lemongrass/WhatsApp Image 2024-09-06 at 15.10.04_2f590f21.jpg'
import lemonbanner1 from '../Lemongrass/Lemonbanner1.jpg'
import lemonimg from '../Lemongrass/Lemongrass how to use.png'
import lemonimg1 from '../Lemongrass/lemonfront.jpg'
import lemonwarnig from '../Lemongrass/Lemongrass  back.png'
import lemonfeatures from '../Lemongrass/Lemongrass how to use   (2).png'
import lemonbowl from '../Lemongrass/lemonbowl.jpg'

import certification from './CERTIFICATION BANNERS22-01.png'

// ===Triphala Powder ====
import triphala2 from '../triphala/Tripahal 2 copy.png'
import triphalabanneer from '../triphala/_Triphla powder banner .png'
import triphalabanner from '../triphala/Tripahla banner.jpg'
import triphala1 from '../triphala/Tripahala Powder  1.png'
import triphala3 from '../triphala/Direction if Thripahala.png'
import triphala4 from '../triphala/Tripahal 2.png'
import triphala5 from '../triphala/triphala powderbenefits.png'

// ======jamunsed import ''
import jamunb1 from '../jamunseed/jamun.jpg'
import jamunf from '../jamunseed/jamunf.jpg'
import jamunb2 from '../jamunseed/jamun2.png'
import jamunb3 from '../jamunseed/jamun3.png'



// ====moringa leaf=====/
import moringa2 from '../Moringa/Moringa Leaf  (1).png'
import moringafeet from '../Moringa/moringaleaffeatures.png'
import moring1 from '../Moringa/rr.jpg'
import moringabanner from '../Moringa/mor.jpg'
import moringause from '../Moringa/moringahowtouse.jpg'
import moringabowl from '../Moringa/moringabowl.jpg'
import moringaback from '../Moringa/moringaback.png'



// ===beetroot ======

import beetbanner from '../beetroot/betbanner1.jpg'
import beetbann from '../beetroot/beetrootPowder.png'
import betroot from '../beetroot/beetroot  powder  front.png'
import beetrootbenefits from '../beetroot/beetrootbenfits .png'
import beetrrotuse1 from '../beetroot/beet root powder  back.png'
import beetrrotuse2 from '../beetroot/use1beet.jpg'
import beetrootuse3 from '../beetroot/sdsd (2).png'

// =================Cow ghee diys ==============


// =====Round  cotton wick[========]
import wick from '../Round/Roundwick1.jpg'
import wick2 from '../Round/Roundwick2.jpg'
import wick3 from '../Round/Roundwick3.jpg'
import wick4 from '../Round/Roundwick4.jpg'
import wick5 from '../Round/Roundwick5.jpg'

import offers from './hh.png'
import offers1 from './hh1.png'


// =========Cow ghee diya ==============
import diya1 from '../cow/Cow ghee diya.jpg'
import cowghee1 from '../cow/cow gheediya 2.jpg'
import cowghee2 from '../cow/cowghee  diya 4.png'
import cowghee3 from '../cow/cow ghee diya 5.jpg'
import cowghee4 from '../cow/cow ghee diya4.png'

// ============Pojjan clay===========
import poojancaly from './product shoot images (6) (1).png'
import pojjaclay2 from '../poojan clay/pujan-clayb-back.jpg'
import pojjaclay3 from '../poojan clay/pujan-clay3.jpg'
import pojjaclay4 from '../poojan clay/puj7.jpg'
import pojjaclay5 from '../poojan clay/puj6.jpg'

// ====long  cotton wick=======
import cottonwicklong from '../long/long.png'
import cottonwicklong2 from '../long/71uSuT5qaAL._AC_SL1280_.jpg'
import cottonwicklong3 from '../long/81opaDRnW3L._AC_SL1500_.jpg'
import cottonwicklong4 from '../long/81B5T2hZmtL._AC_SL1500_.jpg'
import cottonwicklong5 from '../long/long-cotton-wick2.jpg'

import shilajit1 from '../Shilajit/shilajitqq.png'
import shilajit2 from '../Shilajit/shilajitbeneift.png'
import shilajit3 from '../Shilajit/Shilajit.png'
import shilajit4 from '../Shilajit/shilajitw.png'
import shilajit5 from '../Shilajit/Shilajeet.png'
import skinrrbenefits from '../Skinrr/Benefits Of skinrr exotic veda.png'
import skinrrfrontback from '../Skinrr/Skinrr front and back.png'
import skinrrfront from '../Skinrr/Skinrr scar treatment ans skincare exotic veda revaais.png'
import skinrropener from '../Skinrr/Skinrrr image.png'
import skinrruses from '../Skinrr/Skinrruses.png'
import yuvprash from '../Yuvprash/yuvprash.png'
import yuvprash2 from '../Yuvprash/14.png'
import yuvprash3 from '../Yuvprash/yuvpras ingredient.png'
import yuvprash4 from '../Yuvprash/Yuv Prash benefits.png'
import yuvprash5 from '../Yuvprash/Health (7).png'


export const assets = {yuvprash,yuvprash2,yuvprash3,yuvprash5,yuvprash4,
    skinrrbenefits, skinrrfront, skinrrfrontback, skinrropener, skinrruses,
    shilajit1, shilajit2, shilajit3, shilajit4, shilajit5,


    offers, offers1,homebanner6,homebanner7,
    // ===Moringa ====
    moringafeet, moring1, moringa2, moringabanner, moringaback, moringabowl, moringause,



    // =======Alum bar ======
    alum1, alum2, alum3, alum4, alum, alum55, alum5, alumfront, alumuse,

    // ==========alum end===========

    // ===========cow ghee diya ===========
    diya1, wick, wick2, wick3, wick4, wick5,
    // =====Long  cotton wock ============
    cottonwicklong, cottonwicklong2, cottonwicklong3, cottonwicklong4, cottonwicklong5,
    // =============Cowgheediya  inports  =======
    cowghee4, cowghee3, cowghee2, cowghee1,
    // ====pojjan clay-========
    poojancaly, pojjaclay2, pojjaclay3, pojjaclay4, pojjaclay5,


    // ====Home banner =====
    homebanner1, homebanner2, homebanner3, homebanner4, homebanner5,



    // ======Aloe vera Improt  --====
    aloebanner1,
    aloebanner2,
    aloe3, aloe4,
    aloeuse1,
    aloeuse2, aloeback,
    aloebene
    , aloehair, ty,

    betroot, beetbanner, beetbann, beetrootbenefits, beetrrotuse1, beetrrotuse2, beetrootuse3,



    // =====Tripahla Powder =====
    triphala2, triphalabanner, triphala1, triphala3, triphala4, triphala5, triphalabanneer,
    // ===jamunseend'======
    jamunb1, jamunf, jamunb2, jamunb3,



    // ====Lemingrass===
    lemonstrexh, lemonbanner1, lemonimg, lemonimg1, lemonwarnig, lemonfeatures, lemonbowl,


    // ====Shikaki ====
    shikabannersttre, shika2,
    shikabanner, shika1, shika4, shika3, shika5,

    certification,

    // ====henna===
    haircarebanner,


    Hennabanner1,
    Hennabanner2, henna, hennabanner, hennaback, hennause, hennadiy, hennabowl,

    // ====Amla=====
    Amla1, amlabann,
    Amla2,
    Amla3,
    Amla4, Amla5,
    // amlabannn,
    amlaposter, amlabenefits, amlaben, amlafetures, amalpowderbowl,

    // ====Curry Leaf====
    curryleafposter, curryleafposter2,
    Curryfront,
    Curryfetures, Currybenefits, curryback, curryuse,



    // ==sandalwood  igm======


    sandal1,
    sandalfront, sandaluse, sandaluse2, sandaluse3, sandaluse4, sandalback,
    sandalcertification,
    // ===Triphala===







    // ============

    jamuseed,


    // =====Aloevera====



    // ===Multani Mittti=====

    multani, multani33, multanimitti45, multanimitti343, multanimitti4,
    multanibanner1,
    multaniposter,
    skincarebaner,
    Dietbanner,
    collage,
}
